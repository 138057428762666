import { UiService } from './../common/ui.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';

interface LogResponse {
  code: number
  message: string
  data: any
}

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

  constructor(private http: HttpClient, private uiService: UiService) { }
  data
  ngOnInit() {
    this.getPlan()
  }

  getPlan() {
    this.http
      .get<LogResponse>(
        `${environment.APIURL}accounts/plans/`
      )
      .subscribe(
        data => {
          if (data.code === 1) {

            this.data = data.data.exchange

          } else {

            this.uiService.showToast(data.message);
          }
        },
        error => {
          this.uiService.showToast('An error was trigger by the client')

        }
      );
  }
}
