import { AngularRaveModule } from 'angular-rave';
import { UpdateService } from './common/update.service';
import { LogoutComponent } from './dashboard/logout/logout.component';
import { LoadingComponent2 } from './dashboard/loading/loading.component';
import { NeworkFailureComponent2 } from './dashboard/nework-failure/nework-failure.component';
import { environment } from './../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndexComponent } from './index/index.component';
import { MaterialModule } from './material.module';
import { AuthService } from './auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthHttpInterceptor } from './auth/auth-http-interceptor';
import { SimpleDialogComponent } from './common/simple-dialog/simple-dialog.component';
import { UiService } from './common/ui.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NavbarComponent } from './navbar/navbar.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { StudentComponent } from './student/student.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image'; // <-- include scrollPreset

import { LottieModule } from 'ngx-lottie';


import { QuillModule } from 'ngx-quill'



import {
  GoogleApiModule,
  GoogleApiService,
  GoogleAuthService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig
} from "ng-gapi";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './home/nav/nav.component';
import { ClassdetailComponent } from './classdetail/classdetail.component';
import { SigninComponent } from './signin/signin.component';

import { EnrollComponent } from './classdetail/enroll/enroll.component';
import { Angular4PaystackModule } from 'angular4-paystack';
import { NgxStripeModule } from 'ngx-stripe';
import { AllcoursesComponent } from './allcourses/allcourses.component';
import { PriceComponent } from './price/price.component';
import { FooterComponent } from './footer/footer.component';
import { AllclassesComponent } from './allclasses/allclasses.component';
import { EnrolltoclassComponent } from './enrolltoclass/enrolltoclass.component';
import { ClassinfoComponent } from './classinfo/classinfo.component';
import { PolicyComponent } from './policy/policy.component';
import { SupportComponent } from './support/support.component';
import { config } from 'rxjs';
import { JoinclassbylinkComponent } from './joinclassbylink/joinclassbylink.component';
import { SetupComponent } from './setup/setup.component';


export function playerFactory() {
  return import('lottie-web');
}

export function quickModule() {
  return {
    //  syntax:true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript

      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

      [{ 'font': [] }],

      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link', 'image', 'video']                         // link and image, video

    ]
  }
}

let gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.GAPI_CLIENT_ID,
  discoveryDocs: ["https://analyticsreporting.googleapis.com/$discovery/rest?version=v4"],
  scope: environment.GAPI_SCOPES.join(" ")
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: `${environment.APIURL}courses/upload`,
  maxFilesize: 50,
  acceptedFiles: 'image/*,application/*, video/mp4, audio/mp3, audio/wav'
};

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SimpleDialogComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    StudentComponent,
    AuthenticateComponent,
    HomeComponent,
    NavComponent,
    ClassdetailComponent,
    NeworkFailureComponent2,
    LoadingComponent2,
    SigninComponent,
    EnrollComponent,
    AllcoursesComponent,
    PriceComponent,
    FooterComponent,
    AllclassesComponent,
    EnrolltoclassComponent,
    ClassinfoComponent,
    PolicyComponent,
    SupportComponent,
    JoinclassbylinkComponent,
    SetupComponent,
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    LottieModule.forRoot({ player: playerFactory }),
    LazyLoadImageModule.forRoot({}),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    DropzoneModule,
    Angular4PaystackModule.forRoot(environment.PAYSTACK_KEY),
    NgxStripeModule.forRoot(environment.STRIPE_KEY),
    QuillModule.forRoot(),
    AngularRaveModule.forRoot({
      key: environment.FLUTTERWAVE_KEY,
      isTest: true,
    }),
  ],
  entryComponents: [SimpleDialogComponent],
  providers: [AuthService, UiService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  }, {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }, UpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
