import { AuthService } from 'src/app/auth/auth.service';
import { environment } from './../../../environments/environment.prod';
import { Component, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Output()
  @Input() url : string = "";
  @Input() name : string = "";
  APIURL = environment.APIURL;
  login
  constructor(private authService:AuthService) { }

  ngOnInit() {
    this.login = this.authService.isAuthenticate();
  }

 schoolName(name){
   
   if(name.length > 15){
    const data = name.split(" ");
    return data[0];
   }
  return name;
 }
}
