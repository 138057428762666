import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { AuthService } from "../auth/auth.service";
import { Role } from "../auth/role.enum";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient} from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { UiService } from '../common/ui.service'
import { JwtHelperService } from "@auth0/angular-jwt";

interface LogResponse {
  code: number;
  message: string;
  token: string;
  role: Role;
  status: string;
  data: any
}
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  loginError = "";
  loading = false;
  redirectUrl;
  role
  success = false;
  data;
  helper = new JwtHelperService();
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private uiService: UiService) {
     }

  ngOnInit() {
    this.route.queryParamMap.subscribe(
      params => {this.redirectUrl = params.get("redirectUrl")
    });
    this.buildLoginForm()
    //this.authService.logout();
    this.getSchool()
  }
  
  actionLogin(role){
   if(this.redirectUrl){
    this.router.navigate([this.redirectUrl])
   }else{
    if(role ==1){
      this.router.navigate(["/dashboard/home"])
     }else if(role == 2){
      this.router.navigate(["/dashboard/stats"])
     }else if(role == 3){
      this.router.navigate(["/dashboard/catalogs"])
     }
  }

    // this.router.navigate(["/dashboard/catalogs"])
   
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });
  }
  
  async login(submittedForm: FormGroup) {
    this.loading = true
    submittedForm.value.host = location.host
    this.httpClient
      .post<LogResponse>(
        `${environment.APIURL}accounts/studentlogin`,
        submittedForm.value,
      )
      .subscribe(
        data => {
          if (data.code === 1) {
            this.authService.login(data.token);
            this.role = this.helper.decodeToken(data.token).role
            this.actionLogin(this.role);
            // this.router.navigate(["/dashboard"])
          } else {
            this.loading = false;
            this.uiService.showToast(data.message);
          }
        },
        error => {
          this.uiService.showToast('An error was trigger by the client')
          this.loading = false;
        }
      );
  }

  getSchool(){
    this.success = false;
    const host = location.host;
    this.httpClient.get<LogResponse>(`${environment.APIURL}accounts/school/${host}`).subscribe(data=>{
      if(data.code == 1){
        this.success = true;
        this.data = data.data;
        
      }else{
       
        this.uiService.showToast(data.message);
      }
    },
    error=>{
     
     this.uiService.showToast("Could not load school info"); 
    })
  }
}

