import { RaveOptions } from 'angular-rave';
import { StripeCardComponent } from 'ngx-stripe';
import { ElementsOptions, StripeService } from 'ngx-stripe';
import { AuthService } from './../../auth/auth.service';
import { ElementOptions } from 'ngx-stripe';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../../../environments/environment.prod';
import { UiService } from './../../common/ui.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PaystackOptions } from 'angular4-paystack';

interface Iresponse{
  code: number
  message: string
  data: any
}
@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit {
  constructor(private http: HttpClient,private stripeService: StripeService, private route: ActivatedRoute, private router:Router, private authService:AuthService, private uiService:UiService) { }
  @ViewChild(StripeCardComponent,null) card: StripeCardComponent;
  data;
  id;
  APIURL = environment.APIURL
  photo;
  loader: boolean;
  success: boolean;
  failure: boolean;
  loading: boolean;
  onLoading() {
    this.loader = true;
    this.success = false;
    this.failure = false;
  }
  onSuccess() {
    this.loader = false;
    this.success = true;
    this.failure = false;
  }
  onFailure() {
    this.loader = false;
    this.success = false;
    this.failure = true;
  }
  options: PaystackOptions = {
    amount: 0,
    email: this.authService.getEmail(),
    ref: `${Math.ceil(Math.random() * 10e10)}`
  }

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#0aa89e',
        color: '#000',
        lineHeight: '48px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#000'
        }
      }
    }
  };

  paymentOptions: RaveOptions = {
    PBFPubKey: environment.FLUTTERWAVE_KEY,
    customer_email: this.authService.getEmail(),
    customer_firstname: '',
    customer_lastname: '',
    custom_description: 'Payment for enrollment',
    amount: 0,
    customer_phone: '',
    txref: this.generateReference(),
    currency: "NGN"
  }
 
  elementsOptions: ElementsOptions = {
    //locale: 'es'
  };
  time =  Date.now();
  ngOnInit() {
    this.route.paramMap.subscribe(param=>{
     this.id = param.get('id')
     this.getSchool(this.id);
    })
    
  }

  getSchool(id){
    this.onLoading()
    const host = location.host;
    this.http.get<Iresponse>(`${environment.APIURL}users/enroll/${id}/${host}`).subscribe(data=>{
      if(data.code == 1){
        
        this.data = data.data;
       
        this.photo = `${this.APIURL}files/${this.data.course.cover}`
        
        this.options.amount = this.data.course.fee * 100;
        this.paymentOptions.amount = this.data.course.fee;
        if(this.data.course.currency == "dollars"){
          this.paymentOptions.currency = "USD"
        }
        
        this.onSuccess()
      }else{
        this.onFailure()
        this.uiService.showToast(data.message);
      }
    },
    error=>{
      this.onFailure()
     // this.uiService.showToast(""); 
    })
  }

  paymentDone(ref: any) {
    this.loading = true;
    const host = location.host;
      this.http.post<Iresponse>(
        `${environment.APIURL}users/paidenrollment/`,
        {'ref':ref.reference,'amount':this.options.amount,'course':this.data.course.id, 'method':'paystack','host':host}
      ).subscribe(
        response => {
          if (response.code === 1) {
            this.uiService.showToast(response.message);
            this.loading = false; 
            this.router.navigate(['/dashboard/catalogs'])
          } else {
            this.uiService.showToast(response.message);
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.uiService.showToast("Can not process your request. Please try again.")
        }
      );
  }

  paymentCancel() {
    console.log('cancel');
    window.location.reload()
  }

  buy() {
    this.loading = true;
    const name = this.authService.getEmail();
    const host = location.host;
    this.stripeService
      .createToken(this.card.getCard(), { name })
      .subscribe(result => {
        if (result.token) {
           this.http.post<Iresponse>(
             `${environment.APIURL}users/paidenrollment/`,
              {'ref':result.token.id,'amount':this.data.course.fee * 100, 'course':this.data.course.id,'method':'stripe','host':host}
           ).subscribe(
             response => {
               if (response.code === 1) {
                 this.uiService.showToast(response.message);
                 this.loading = false; 
                 this.router.navigate(['/dashboard/catalogs'])
               } else {
                 this.uiService.showToast(response.message);
                 this.loading = false;
               }
             },
             error => {
               this.loading = false;
               this.uiService.showToast("Can not process your request. Please try again.")
             }
           );
        } else if (result.error) {
          this.loading = false;
         this.uiService.showToast(result.error.message)
        }
      });
  }

  enroll() {
    this.loading = true;
    const host = location.host;
      this.http.post<Iresponse>(
        `${environment.APIURL}users/freeenrollment/`,
        {'course':this.data.course.id,'host':host}
      ).subscribe(
        response => {
          if (response.code === 1) {
            this.uiService.showToast(response.message);
            this.loading = false; 
            this.router.navigate(['/dashboard/catalogs'])
          } else {
            this.uiService.showToast(response.message);
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.uiService.showToast("Can not process your request. Please try again.")
        }
      );
  }

  onclosePayment(event) {
    console.log(event)
    if(event){
     this.loading = true;
     const host = location.host;
     const res = event.tx.txRef
     this.http.post<Iresponse>(
       `${environment.APIURL}users/paidenrollment/`,
       {'ref':res,'amount':this.paymentOptions.amount,'course':this.data.course.id, 'method':'flutter','host':host}
     ).subscribe(
       response => {
         if (response.code === 1) {
           this.uiService.showToast(response.message);
           this.loading = false; 
           this.router.navigate(['/dashboard/catalogs'])
         } else {
           this.uiService.showToast(response.message);
           this.loading = false;
         }
       },
       error => {
         this.loading = false;
         this.uiService.showToast("Can not process your request. Please try again.")
       }
     );
    }
 
  }
  

  paymentSuccess(event) {
    console.log(event)
    if(event){
     this.loading = true;
     const host = location.host;
     const res = event.tx.txRef
     this.http.post<Iresponse>(
       `${environment.APIURL}users/paidenrollment/`,
       {'ref':res,'amount':this.paymentOptions.amount,'course':this.data.course.id, 'method':'flutter','host':host}
     ).subscribe(
       response => {
         if (response.code === 1) {
           this.uiService.showToast(response.message);
           this.loading = false; 
           this.router.navigate(['/dashboard/catalogs'])
         } else {
           this.uiService.showToast(response.message);
           this.loading = false;
         }
       },
       error => {
         this.loading = false;
         this.uiService.showToast("Can not process your request. Please try again.")
       }
     );
    }
 
  }
   paymentInit(paymentInstance) {
     
     console.log(paymentInstance);
     console.log('Payment about to begin', paymentInstance);
   }
   generateReference(): string {
       let text = '';
       const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       for (let i = 0; i < 10; i++) {
           text += possible.charAt(Math.floor(Math.random() * possible.length));
       }
  
       return text;
   }

}
