import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment.prod';
import { AuthService } from './../auth/auth.service';
import { UiService } from './../common/ui.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

interface Iresponse{
  code: number
  message: string
  data: any
}
@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

 
  constructor(private http: HttpClient, private router:Router, private route:ActivatedRoute, private uiService:UiService, private authService:AuthService) { }
  data;
  APIURL = environment.APIURL
  loader: boolean;
  success: boolean;
  failure: boolean;
  photo;
  login
  onLoading() {
    this.loader = true;
    this.success = false;
    this.failure = false;
  }
  onSuccess() {
    this.loader = false;
    this.success = true;
    this.failure = false;
  }
  onFailure() {
    this.loader = false;
    this.success = false;
    this.failure = true;
  }
  ngOnInit() {
    this.login = this.authService.isAuthenticate();
    this.route.paramMap.subscribe(data=>{
      this.getSchool(data.get("id"))
    })
    
  }

  getSchool(id){
    this.onLoading()
    const host = location.host;
  this.http.get<Iresponse>(`${environment.APIURL}marketplace/coursesetup?host=${host}&token=${id}`).subscribe(data=>{
      if(data.code == 1){
        this.authService.login(data.data.token);
        if(this.authService.isAuthenticate()){
          this.router.navigate([data.data.url])
        }else{
          this.data = data.data
          this.onSuccess()
        }
      }else if(data.code==2){
        this.data = data.data
        this.onSuccess();
      }else{
        this.onFailure()
        this.uiService.showToast(data.message);
      }
    },
    error=>{
      this.onFailure()
     this.uiService.showToast("Could not load school info"); 
    })
  }
}

