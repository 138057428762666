import { ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment.prod';
import { UiService } from './../common/ui.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

interface Iresponse{
  code: number
  message: string
  data: any
}
@Component({
  selector: 'app-classdetail',
  templateUrl: './classdetail.component.html',
  styleUrls: ['./classdetail.component.scss']
})
export class ClassdetailComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private uiService:UiService) { }
  data;
  id;
  APIURL = environment.APIURL
  photo;
  loader: boolean;
  success: boolean;
  failure: boolean;
  onLoading() {
    this.loader = true;
    this.success = false;
    this.failure = false;
  }
  onSuccess() {
    this.loader = false;
    this.success = true;
    this.failure = false;
  }
  onFailure() {
    this.loader = false;
    this.success = false;
    this.failure = true;
  }
  ngOnInit() {
    this.route.paramMap.subscribe(param=>{
     this.id = param.get('id')
     this.getSchool(this.id)
    })
    
  }

  getSchool(id){
    this.onLoading()
    const host = location.host;
    this.http.get<Iresponse>(`${environment.APIURL}accounts/classdetail/${id}/${host}`).subscribe(data=>{
      if(data.code == 1){
        
        this.data = data.data;
      
          this.photo = `${this.APIURL}files/${this.data.course.cover}`        
        this.onSuccess()
      }else{
        this.onFailure()
        this.uiService.showToast(data.message);
      }
    },
    error=>{
      this.onFailure()
     this.uiService.showToast("Could not load school info"); 
    })
  }

}
