import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
 login
  constructor(private authService:AuthService) { }

  ngOnInit() {
    this.login = this.authService.isAuthenticate()
  }

}
