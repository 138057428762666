import { environment } from './../../environments/environment.prod';
import { AuthService } from './../auth/auth.service';
import { UiService } from './../common/ui.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

interface Iresponse{
  code: number
  message: string
  data: any
}
@Component({
  selector: 'app-allcourses',
  templateUrl: './allcourses.component.html',
  styleUrls: ['./allcourses.component.scss']
})
export class AllcoursesComponent implements OnInit {

  constructor(private http: HttpClient, private uiService:UiService, private authService:AuthService) { }
  data;
  APIURL = environment.APIURL
  loader: boolean;
  success: boolean;
  failure: boolean;
  photo;
  login
  onLoading() {
    this.loader = true;
    this.success = false;
    this.failure = false;
  }
  onSuccess() {
    this.loader = false;
    this.success = true;
    this.failure = false;
  }
  onFailure() {
    this.loader = false;
    this.success = false;
    this.failure = true;
  }
  ngOnInit() {
    this.login = this.authService.isAuthenticate();
    this.getSchool()
  }

  getSchool(){
    this.onLoading()
    const host = location.host;
  this.http.get<Iresponse>(`${environment.APIURL}accounts/courses/${host}`).subscribe(data=>{
      if(data.code == 1){
        this.data = data.data;
        this.onSuccess()
      }else{
        this.onFailure()
        this.uiService.showToast(data.message);
      }
    },
    error=>{
      this.onFailure()
     this.uiService.showToast("Could not load school info"); 
    })
  }
}
