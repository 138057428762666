import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { AuthService } from "../auth/auth.service";
import { Role } from "../auth/role.enum";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { UiService } from '../common/ui.service'

interface LogResponse {
  code: number;
  message: string;
  token: string;
  role: Role;
  status: string;
  data: any
}
@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {
  loginForm: FormGroup;
  loginError = "";
  loading = false;
  getStarted = true;
  complete = false;
  errorMessage;
  showError = false;
  success = false;
  data;
  redirectUrl;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private uiService: UiService) {
   
  }

  ngOnInit() {
 
    this.route.queryParamMap.subscribe(
      params => {this.redirectUrl = params.get("redirectUrl")
    });
 
    this.buildLoginForm()
 
    this.getSchool();
 
  }
  
  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required,Validators.minLength(6)]],
      name: ["", [Validators.required]],
      phone: ["",[]],
      repeatpassword: ["", [Validators.required]],
      term: ["", [Validators.required]]
    });
  }

  async create(submittedForm: FormGroup) {
    this.loading = true
    submittedForm.value.host = location.host
    if (submittedForm.value.password != submittedForm.value.repeatpassword) {
      this.uiService.showToast('Password do not match')
      this.loading = false;
    }else if(submittedForm.invalid){
      this.uiService.showToast('All field are required')
      this.loading = false; 
    }else{
      this.httpClient
      .post<LogResponse>(
        `${environment.APIURL}accounts/student/`,
        submittedForm.value,
      )
      .subscribe(
        data => {
          if (data.code === 1) {
            this.authService.login(data.token);
            this.router.navigate([ this.redirectUrl || '/dashboard/catalogs'])
          } else {
            this.loading = false;
            this.uiService.showToast(data.message);
          }
        },
        error => {
          this.uiService.showToast('An error was trigger by the client')
          this.loading = false;
        }
      );
    }
  
  }
  getSchool(){
    const host = location.host;
    this.success = false;
    this.httpClient.get<LogResponse>(`${environment.APIURL}accounts/school/${host}`).subscribe(data=>{
      
      if(data.code == 1){
        this.success = true;
        this.data = data.data;
        
      }
      
      else{ 
        this.uiService.showToast(data.message);
      }

    },
    error=>{
     
     this.uiService.showToast("Could not load school info"); 
    })
  }
}
