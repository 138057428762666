export const environment = {
  production: true,

  //  APIURL: "http://localhost/learning/public/",
  //  FILEURL: "http://localhost/learning/public/files/",
   
   APIURL: "https://api.clique.ng/lms/public/",
   FILEURL: "https://api.clique.ng/lms/public/files/",

   LIVEURL: 'https://liveclass.clique.ng/', 

  // LIVEURL: 'http://localhost:8090/', 

    //PAYSTACK_KEY: 'pk_test_caf268193fad2d15a202977bd8553184c99a52dd',
   PAYSTACK_KEY: 'pk_live_fb4e94719127b3170ef3f583aff6bf3e8c19b429',
   STRIPE_KEY: 'pk_test_BVwZgkXO387E8Ee5pMJBuFjy00os7evhxn',
   FLUTTERWAVE_KEY: "FLWPUBK-96cffa6a3caa95f869f6a33723f39c04-X",
   FLUTTERWAVE_TEST: "FLWPUBK-96cffa6a3caa95f869f6a33723f39c04-X",
  GAPI_DEVELOPER_KEY:"AIzaSyDkwM_D5irddbylnw8-AdhT9Sj5rEUyKIw",
  GAPI_CLIENT_ID: "689834590328-ub7nl9okkcu73c2jeh23odr185urr6c9.apps.googleusercontent.com",
  GAPI_SCOPES:[
    "https://www.googleapis.com/auth/drive.file"
  ],
  ONEDRIVE_APP_ID: "7debbbe5-15e1-41ae-95e4-78c9fb401278",

  //URL THAT CAN RELAY OR HANDLE CORS. (USED WITH GOOGLE DRIVE FETCH FOR BLOB CONTENT)
  CORS_RELAY_URL: "https://cors-anywhere.herokuapp.com/",


  //AMAZON CONSTATNT CONFIGURATION

  AWS_BUCKET_NAME:"cliqueng",
  AWS_REGION:"us-east-1",
  AWS_ACCESS_KEY_ID:"AKIAQ7D24WKYCA3F3GB3",
  AWS_SECRET_KEY:"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",
  AWS_DIR_NAME:"contents",
  AWS_IDENTITY_POOL_ID:"us-east-1:bf766ed9-1c72-44b8-9cda-ff41741a1108"


};
