import { SetupComponent } from './setup/setup.component';
import { JoinclassbylinkComponent } from './joinclassbylink/joinclassbylink.component';
import { PolicyComponent } from './policy/policy.component';
import { SupportComponent } from './support/support.component';
import { ClassinfoComponent } from './classinfo/classinfo.component';
import { AllclassesComponent } from './allclasses/allclasses.component';
import { PriceComponent } from './price/price.component';
import { AllcoursesComponent } from './allcourses/allcourses.component';
import { LogoutComponent } from './dashboard/logout/logout.component';
import { EnrollComponent } from './classdetail/enroll/enroll.component';
import { SigninComponent } from './signin/signin.component';
import { ClassdetailComponent } from './classdetail/classdetail.component';
import { HomeComponent } from './home/home.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { StudentComponent } from './student/student.component';
import { EnrolltoclassComponent } from './enrolltoclass/enrolltoclass.component';


const routes: Routes = [


  { path: '', component: HomeComponent},
  //  { path: 'index', component: IndexComponent },
  {path: 'index', component: HomeComponent},

  // { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },


 { path: 'courses', component: AllcoursesComponent },
 { path: 'course/:id', component: ClassdetailComponent },
 { path: 'course/enroll/:id', component: EnrollComponent },
 { path: 'classes', component: AllclassesComponent },
 { path: 'joinclass/:id', component: JoinclassbylinkComponent },
 { path: 'class/:id', component: ClassinfoComponent },
 { path: 'class/enroll/:id', component: EnrolltoclassComponent },
 { path: 'login', component: SigninComponent },
 

 { path: 'signin', component: SigninComponent },
 { path: 'signup', component: StudentComponent },

  { path: 'support', component: SupportComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'price', component: PriceComponent },

  { path: 'setup/:id', component: SetupComponent },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

