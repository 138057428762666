import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { AuthService } from "../auth/auth.service";
import { Role } from "../auth/role.enum";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { UiService } from '../common/ui.service'

interface LogResponse {
  code: number;
  message: string;
  token: string;
  role: Role;
  status: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loginForm: FormGroup;
  loginError = "";
  loading = false;
  getStarted = true;
  complete = false;
  errorMessage;
  showError = false;
  redirectUrl;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private uiService: UiService) {
    route.paramMap.subscribe(
      params => (this.redirectUrl = params.get("redirectUrl"))
    );
  }

  ngOnInit() {
    this.buildLoginForm()
  }
  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required]],
      password: ["", [Validators.required,Validators.minLength(6)]],
      name: ["", [Validators.required]],
      repeatpassword: ["", [Validators.required]],
      company: ["", [Validators.required]],
      cphone: ["", []],
      cemail: ["", []],
      domain: ["", [Validators.required]],
      type: ["", [Validators.required]],
      term: ["", [Validators.required]]
    });
  }
  async login(submittedForm: FormGroup) {
    this.loading = true
    
    this.httpClient
      .post<LogResponse>(
        `${environment.APIURL}accounts/create/`,
          submittedForm.value,
      )
      .subscribe(
        data => {
          if (data.code == 1) {
            this.authService.login(data.token);
            this.router.navigate(['/dashboard/courses/create'])
          } else {
            this.loading = false;
            this.uiService.showToast(data.message);
          }
        },
        error => {
          this.uiService.showToast('An error was trigger by the client')
          this.loading = false;
        }
      );
  }

  async validate(submittedForm: FormGroup) {
    this.loading = true
    this.showError = false
    if (submittedForm.value.password != submittedForm.value.repeatpassword) {
      this.uiService.showToast('Password do not match')
      this.loading = false;
    }else if(submittedForm.value.password.length < 6){
      this.uiService.showToast('Password must be atleast 6 character')
      this.loading = false;
    }else if(!submittedForm.get('email').valid){
      this.uiService.showToast('Please enter a valid email address')
      this.loading = false;
    }else if(submittedForm.value.password == '' || submittedForm.value.email == '' || submittedForm.value.name == '' || submittedForm.value.term == ''){
      this.uiService.showToast('All field are required')
      this.loading = false;
    } else {
      this.httpClient
        .post<LogResponse>(
          `${environment.APIURL}accounts/validates/`,
          { "email": submittedForm.value.email },
        )
        .subscribe(
          data => {
            if (data.code === 1) {
             this.getStarted = false;
             this.complete = true;
             this.loading = false;
            } else {
              this.loading = false;
              this.uiService.showToast(data.message);
              this.errorMessage = data.message;
              this.showError = true;
            }
          },
          error => {
            this.uiService.showToast('Could not connect at the moment.')
            this.loading = false;
          }
        );
    }
  }
}
