import { UiService } from './../common/ui.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

interface IResponse{
code:number;
message: string;
data: any
}

@Component({
  selector: 'app-joinclassbylink',
  templateUrl: './joinclassbylink.component.html',
  styleUrls: ['./joinclassbylink.component.scss']
})
export class JoinclassbylinkComponent implements OnInit {
  id;
  loading =  true
  failed = false
  title = ""
  message = ""
  constructor(private router:ActivatedRoute, private route:Router, private uiservice:UiService, private http:HttpClient) { }

  ngOnInit() {
   this.router.paramMap.subscribe(data=>{
    this.id = data.get('id')
    this.enroll(this.id)
   }) 
  }

  enroll(id){
    this.loading = true
    this.http.get<IResponse>(`${environment.APIURL}users/joinclassbylink/${id}`).subscribe(res=>{
      if(res.code == 1){
       this.uiservice.showToast(res.message) 
       this.route.navigate(["/dashboard/catalogs"]) 
      }else{
        this.loading = false;
        this.failed = true
        this.uiservice.showToast(res.message) 
        this.message = res.message
        this.title = "Invalid Class Code"
      } 
    },error=>{
      this.loading = false;
      this.failed = true
      this.uiservice.showToast("Network Error!") 
      this.message = "Please check your internet and try again."
      this.title = "Network Error!"
    })
  }

}
