import { NgModule } from '@angular/core';

import {
  MatButtonModule, MatTabsModule,
  MatTableModule, MatExpansionModule, MatDatepickerModule, MatTooltipModule, MatToolbarModule, MatIconModule, MatRadioModule, MatFormFieldModule,
  MatInputModule, MatCardModule, MatCheckboxModule, MatSelectModule, MatDialogModule, MatSnackBarModule,
  MatStepperModule, MatNativeDateModule, MatSlideToggleModule, MatChipsModule, MatButtonToggleModule, MatDividerModule, MatListModule, MatSidenavModule, MatMenuModule, MatPaginatorModule, MatBadgeModule, MatProgressSpinnerModule, MatProgressBarModule
} from '@angular/material';


@NgModule({
  imports: [MatButtonModule,
    MatTableModule,
    MatPaginatorModule, MatProgressSpinnerModule, MatProgressBarModule,
    MatBadgeModule, MatProgressSpinnerModule,
    MatMenuModule, MatTabsModule, MatSlideToggleModule, MatExpansionModule, MatNativeDateModule, MatTooltipModule, MatDatepickerModule, MatToolbarModule, MatRadioModule, MatCheckboxModule, MatDialogModule, MatSnackBarModule, MatIconModule, MatFormFieldModule,
    MatInputModule, MatCardModule, MatStepperModule, MatChipsModule, MatButtonToggleModule, MatSelectModule, MatDividerModule, MatListModule, MatSidenavModule],
  exports: [MatButtonModule,
    MatTableModule, MatProgressSpinnerModule,
    MatPaginatorModule,MatProgressSpinnerModule, MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule, MatTabsModule, MatSlideToggleModule, MatExpansionModule, MatDatepickerModule, MatTooltipModule, MatToolbarModule, MatDialogModule, MatRadioModule, MatSnackBarModule, MatCheckboxModule, MatIconModule, MatFormFieldModule,
    MatInputModule, MatCardModule, MatNativeDateModule, MatChipsModule, MatButtonToggleModule, MatStepperModule, MatSelectModule, MatDividerModule, MatListModule, MatSidenavModule],
  declarations: []
})

export class MaterialModule { }
